<template>
    <v-container>
            <AppBar :rutas="rutas" />
            <v-row class="mx-5">
                 <router-view/>
            </v-row>
        </v-container>
</template>
<script>
import AppBar from '../components/celulas/AppBarAdmin.vue'
export default {
    components: {
        AppBar
    },
    data(){
        return {
            rutas: [
                {
                    icon: "mdi-account",
                    text: 'Areas',
                    redireccion: "crud_sectores"
                },
                {
                     icon: "mdi-file-document-multiple-outline",
                    text: 'Tipos documentales',
                    redireccion: "crud_documentos"
                },
                {
                    icon: 'mdi-sitemap-outline',
                    text: 'Workflows',
                    redireccion: "crud_workflows"
                },
                {
                    icon: 'mdi-text-box-search-outline',
                    text: 'Busquedas',
                    redireccion: "crud_validaciones"
                },
                {
                    icon: 'mdi-chart-multiple',
                    text: 'Reportes documentales',
                    redireccion: "reporte_documental"
                },
                {
                    icon: 'mdi-chart-multiple',
                    text: 'Reportes de flujo',
                    redireccion: "reporte_workflow"
                },
            ]
        }
    }
}
</script>