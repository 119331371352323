<template>
    <v-content>
      <v-navigation-drawer
            v-model="drawer"
            :clipped="vars.clipped"
            :app="vars.app"
            :absolute="vars.absolute"
            :bottom="vars.bottom"
            :fixed="vars.fixed"
            :hide-overlay="vars.overlay"
            :permanent="vars.permanent"
            :stateless="vars.stateless"
            >
            <v-row class="ma-2">
                <v-col>
                    <v-card rounded="xl">
                        <v-card-actions>
                            <v-img contain :aspect-ratio="16/9" :src="require('../../assets/recepcionista.png')" >
                            </v-img>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="ma-2">
                <v-col class="d-flex align-stretch">
                    <v-card rounded="xl" min-height="300" width="100%" >
                        <v-list dense>
                            <v-list-item @click="$router.push({name: route.redireccion })" v-for="(route,index) in rutas" :key="index" link>
                                
                                <v-list-item-icon>
                                <v-icon>{{ route.icon }}</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                    <v-list-item-title>{{ route.text }}</v-list-item-title>
                                    </v-list-item-content>
                            
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        
        </v-navigation-drawer>  
      <v-app-bar
        app
        color="indigo darken-4"
        dark
        :clipped-left="clipped_left"
      >
         <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title> {{titulo}} </v-toolbar-title>
        

        <v-spacer></v-spacer>

        <v-btn @click="$vuetify.theme.dark=!$vuetify.theme.dark" icon>
          <v-icon v-if="$vuetify.theme.dark==false" >mdi-weather-night</v-icon>
          <v-icon v-else>mdi-white-balance-sunny</v-icon>
        </v-btn>
        <v-btn @click="salir()" icon>
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-app-bar>
    </v-content>
    
</template>
<script>
//import Menus from './Menus.vue'
export default {
    components: {
        //Menus,
    },
    props: {
        rutas: {
            type: Array,
        },
        titulo: {
        type: String,
        default: "AudiDOC"
      },
      clipped_left: {
        type: Boolean,
        default: () => { return true}
      }
    },
    data(){
        return {
            drawer: true,
            vars: {
                clipped:true,
                app:true,
                absolute:false,
                bottom:false,
                fixed:false,
                overlay:true,
                permanent:false,
                stateless:true,
            },
            
        }
    },
    created(){
      console.log('asdasda')
      console.log(this.rutas);

      if(!this.clipped_left){
        this.vars = {
                clipped:false,
                app:true,
                absolute:false,
                bottom:false,
                fixed:false,
                overlay:false,
                permanent:false,
                stateless:false,
        }
      }

      
    },
    methods: {
      salir(){
        //this.$store.state.datos_sesion.datos = null
        this.$router.push('/')

      }
    }
}
</script>